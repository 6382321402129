import { formDataToObject } from '@/utils/api'
import { wait } from '@/utils/wait'

const states = {
	success: 'success',
	error: 'error',
	default: 'default'
} as const

export default {
	name: 'diagnosticForm',
	component() {
		const root = this.$el as HTMLDivElement
		const form = root.querySelector('form') as HTMLFormElement
		const endpoint = form.getAttribute('action') || ''
		const method = form.getAttribute('method') || 'GET'

		const progressBarEl = document.getElementById('quiz-progress-bar') as HTMLElement
		const steps = Array.from(form.querySelectorAll('[data-step]')) as HTMLElement[]
		const totalSteps = steps.length
		const dom = {
			steps: steps.map(step => ({
				step,
				inputs: Array.from(step.querySelectorAll('input')),
				autoNext: step.dataset.autoNext !== undefined
			}))
		}

		return {
			currentStepIdx: 0,
			loading: false,
			statusText: '',
			status: states.default as any,
			progress: 0,
			totalSteps,

			init() {
				if (process.env.NODE_ENV !== 'development') {
					window._lenis?.stop()
					window.addEventListener('beforeunload', this.confirmExit.bind(this))
					window._lenis?.scrollTo('#step-0', { force: true, lock: true, immediate: true })
				}
			},

			destroy() {
				if (process.env.NODE_ENV !== 'development') {
					window._lenis?.start()
					window.removeEventListener('beforeunload', this.confirmExit.bind(this))
				}
			},

			gotoStep(idx: number, withValidation = false) {
				const needsValidation = withValidation && Boolean(idx > this.currentStepIdx)
				const isStepValid = this.validateStep()

				if (!needsValidation || isStepValid) {
					this.clearValidation(idx)

					this.currentStepIdx = idx
					this.updateProgress()

					window._lenis?.scrollTo(`#step-${idx}`, { force: true, lock: true })
					dom.steps[idx].inputs[0]?.focus()

					window.Shopify.analytics.publish('diagnostic_goto_step', {
						currentStep: this.currentStepIdx,
						totalSteps: this.totalSteps
					})
					if (window.dataLayer) {
						window.dataLayer.push({
							event: 'diagnostic_goto_step',
							currentStep: this.currentStepIdx,
							totalSteps: this.totalSteps
						})
					}
				}
			},

			prevStep() {
				this.gotoStep(this.currentStepIdx - 1)
				window.Shopify.analytics.publish('diagnostic_prev_step', {
					currentStep: this.currentStepIdx,
					totalSteps: this.totalSteps
				})

				if (window.dataLayer) {
					window.dataLayer.push({
						event: 'diagnostic_prev_step',
						currentStep: this.currentStepIdx,
						totalSteps: this.totalSteps
					})
				}
			},

			nextStep() {
				this.gotoStep(this.currentStepIdx + 1, true)
				window.Shopify.analytics.publish('diagnostic_next_step', {
					currentStep: this.currentStepIdx,
					totalSteps: this.totalSteps
				})
				if (window.dataLayer) {
					window.dataLayer.push({
						event: 'diagnostic_next_step',
						currentStep: this.currentStepIdx,
						totalSteps: this.totalSteps
					})
				}
			},

			validateStep(idx?: number) {
				const { inputs } = dom.steps[idx || this.currentStepIdx]

				return inputs.every(input => {
					const isInputValid = input.checkValidity()
					input.setAttribute('aria-invalid', (!isInputValid).toString())
					return isInputValid
				})
			},

			clearValidation(idx: number) {
				const { inputs } = dom.steps[idx]
				inputs.forEach(input => input.removeAttribute('aria-invalid'))
			},

			handleInputChange(idx: number) {
				const { autoNext } = dom.steps[idx]
				this.clearValidation(idx)

				if (autoNext) {
					setTimeout(() => {
						this.gotoStep(this.currentStepIdx + 1)
					}, 400)
				}
			},

			updateProgress() {
				this.progress = this.currentStepIdx / (totalSteps - 1)
				progressBarEl.style.transform = `scaleX(${this.progress})`
			},

			async handleSubmit(e: Event) {
				e.preventDefault()

				if (this.loading) return

				this.loading = true

				try {
					await wait(3000)

					const formData = new FormData(form)
					const body: any = formDataToObject(formData)

					localStorage.setItem('diagnostic-firstname', body['customer-email-information-firstname'])

					const response = await fetch(endpoint, {
						method,
						body: JSON.stringify(body)
					})

					if (!response.ok) {
						throw new Error('Network response was not ok')
					}

					const data = await response.json()
					localStorage.setItem(`diagnostic-result`, JSON.stringify(data))
					if (window.dataLayer) {
						window.dataLayer.push({
							event: 'completed_diagnostic'
						})
					}

					if (data.location) {
						this.statusText = data.message
						this.status = states.success
						window.location.pathname = data.location
					} else {
						console.error('[diagnosticForm]: Did not receive location', data)
						throw new Error('Did not receive location')
					}
				} catch (error) {
					console.error('[diagnosticForm]: Error submitting form', error)
					this.statusText = 'Something went wrong, please try later'
					this.status = states.error
				} finally {
					this.loading = false
				}
			},

			confirmExit(e: BeforeUnloadEvent) {
				if (this.progress < 1 && !this.loading) {
					e.preventDefault()
				}
			}
		}
	}
}
