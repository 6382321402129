export default {
	name: 'freeProducts',
	component(props) {
		const { productIds, productThreshold } = props

		return {
			showNoFreeGift: false,

			removeFreeProduct(cart) {
				const items = cart.items
				if (cart.items_subtotal_price < productThreshold * 100) {
					const discountedCartItem = items.find(item => {
						return (item.final_price === 0 || item.properties._discount_kit === 'free') && productIds.includes(item.product_id)
					})
					if (discountedCartItem) window.liquidAjaxCart.change({ id: discountedCartItem.key, quantity: 0 })
				} else if (!productIds?.length) {
					const discountedCartItem = items.find(item => {
						return item.final_price === 0 || item.properties._discount_kit === 'free'
					})
					if (discountedCartItem) window.liquidAjaxCart.change({ id: discountedCartItem.key, quantity: 0 })
				}
			},

			init() {
				if (!productIds?.length) {
					this.removeFreeProduct(window.liquidAjaxCart.cart)
				}

				document.addEventListener('liquid-ajax-cart:request-end', event => {
					const { cart } = event.detail
					this.removeFreeProduct(cart)
				})
			},

			selectFreeProduct(variantId) {
				const items = window.liquidAjaxCart.cart.items
				const discountedCartItem = items.find(item => {
					return (item.final_price === 0 || item.properties._discount_kit === 'free') && productIds.includes(item.product_id)
				})

				const addFreeProduct = () => {
					window.liquidAjaxCart.add({
						items: [
							{
								id: variantId,
								quantity: 1,
								price: 0,
								properties: {
									// eslint-disable-next-line @typescript-eslint/naming-convention
									_discount_kit: 'free'
								}
							}
						]
					})
				}

				if (discountedCartItem && discountedCartItem.variant_id !== variantId && variantId !== 'none') {
					window.liquidAjaxCart.change({ id: discountedCartItem.key, quantity: 0 })
					addFreeProduct()
					sessionStorage.setItem('showNoFreeGift', 'false')
				} else if (!discountedCartItem && variantId !== 'none') {
					addFreeProduct()
					sessionStorage.setItem('showNoFreeGift', 'false')
				} else if (variantId === 'none') {
					sessionStorage.setItem('showNoFreeGift', 'true')
					if (discountedCartItem) window.liquidAjaxCart.change({ id: discountedCartItem.key, quantity: 0 })
				}
			}
		}
	}
}
